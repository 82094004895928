import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IStore } from '../types'
import {
  getWittenMessages,
  getMiniChat,
  getWittenMessage,
  deleteWittenMessage,
  deleteFile,
  getMiniChatMessages,
} from './actions'
import { IMessageWritten, IMiniChat, IChatsWrittenState } from './types'

const filterMessages = (messages: IMessageWritten[], message: IMessageWritten) => {
  return messages?.filter((item) => {
    if (item.absnum !== message.absnum) return true
    if (item.absnum === message.absnum && item.part_id !== message.part_id) return true
    return false
  })
}

const sortMessages = (a: IMessageWritten, b: IMessageWritten) => {
  const aSum =
    a.is_new * 4 + a.is_mini_chat * 5 + a.is_new_clarified + (a.is_sort_processed ? 0 : 2) + (a.is_clarified ? 0 : 1)
  const bSum =
    b.is_new * 4 + b.is_mini_chat * 5 + b.is_new_clarified + (b.is_sort_processed ? 0 : 2) + (b.is_clarified ? 0 : 1)

  if (aSum > bSum) {
    return -1
  }

  if (aSum < bSum) {
    return 1
  }

  if (a.is_mini_chat && b.is_mini_chat) {
    if (a.status !== b.status) {
      a.status - b.status
    } else {
      return new Date(b.mini_chat_at).getTime() - new Date(a.mini_chat_at).getTime()
    }
  }

  if (a.is_new_clarified && b.is_new_clarified) {
    return new Date(b.mini_chat_at).getTime() - new Date(a.mini_chat_at).getTime()
  }

  if (a.is_clarified && b.is_clarified) {
    return new Date(b.clarified_at).getTime() - new Date(a.clarified_at).getTime()
  }

  if ((a.is_new && b.is_new) || (a.is_sort_processed && b.is_sort_processed)) {
    return b.absnum - a.absnum
  }

  return new Date(a.deadline).getTime() - new Date(b.deadline).getTime()
}

const filterMiniChatMessages = (messages: IMiniChat[], message: IMiniChat) => {
  return messages?.filter((item) => {
    if (item.absnum !== message.absnum) return true
    if (item.absnum === message.absnum && item.part_id !== message.part_id) return true
    return false
  })
}

const updateMessages = (messages: IMessageWritten[], message: IMessageWritten) => {
  return messages.map((item) => {
    if (item.absnum === message.absnum && item.part_id === message?.part_id) {
      return message
    }
    return item
  })
}

const initialState: IStore['chatsWritten'] = {
  data: null,
  message: null,
  miniChat: null,
  page: 1,
  loading: false,
}

export const slice = createSlice({
  name: 'chatsWritten',
  initialState,
  reducers: {
    changePage: (state, action) => {
      state.page = action.payload
      state.loading = true
    },
    updateMessage: (state, action: PayloadAction<IMessageWritten>) => {
      if (state.data && action.payload) {
        let messages = state.data.messages
        if (action.payload.is_mini_chat) {
          const filteredMessages = filterMessages(state.data.messages, action.payload)
          messages = [action.payload, ...filteredMessages]
        } else {
          messages = updateMessages(state.data.messages, action.payload).sort(sortMessages)
        }

        state.data = { ...state.data, messages }
      }
      if (
        state.message &&
        action.payload &&
        state.message.absnum === action.payload.absnum &&
        state.message.part_id === action.payload?.part_id
      ) {
        state.message = action.payload
      }
    },
    addMessage: (state, action: PayloadAction<IMessageWritten>) => {
      if (state.data && action.payload) {
        const messages = filterMessages(state.data.messages, action.payload)
        state.data = { ...state.data, messages: [action.payload, ...messages].sort(sortMessages) }
      }
    },
    deleteMessage: (state, action: PayloadAction<IMessageWritten>) => {
      if (state.data && action.payload) {
        const messages = filterMessages(state.data.messages, action.payload)
        state.data = { ...state.data, messages }
      }
    },
    addeMessageMiniChat: (state, action: PayloadAction<IMiniChat>) => {
      if (
        state.miniChat &&
        state.message?.absnum === action.payload.message_id &&
        state.message?.part_id === action.payload.part_id
      ) {
        const messages = filterMiniChatMessages(state.miniChat.messages, action.payload)
        messages.push(action.payload)
        state.miniChat = { ...state.miniChat, messages }
      }
    },
    setMiniChat: (state, action: PayloadAction<IChatsWrittenState['miniChat']>) => {
      state.miniChat = action.payload
    },
    setMessage: (state, action: PayloadAction<IChatsWrittenState['message']>) => {
      state.message = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWittenMessages.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getWittenMessages.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(getWittenMessages.fulfilled, (state, action) => {
      state.data = action.payload
      state.page = action.payload?.page || state.page
      state.loading = false
    })
    builder.addCase(getMiniChat.fulfilled, (state, action) => {
      state.miniChat = action.payload
    })
    builder.addCase(getMiniChatMessages.fulfilled, (state, action) => {
      if (state.miniChat && action.payload) {
        state.miniChat = {
          ...action.payload,
          messages: [...action.payload.messages, ...state.miniChat.messages],
        }
      }
    })
    builder.addCase(getWittenMessage.fulfilled, (state, action) => {
      state.message = action.payload
    })
    builder.addCase(deleteWittenMessage.fulfilled, (state, action) => {
      if (state.data && action.payload) {
        const messages = filterMessages(state.data.messages, action.payload)
        state.data = { ...state.data, messages }
      }
    })
    builder.addCase(deleteFile.fulfilled, (state, action) => {
      if (state.data && action.payload) {
        const messages = updateMessages(state.data.messages, action.payload)
        state.data = { ...state.data, messages }
      }
      if (state.message && action.payload) {
        state.message = { ...state.message, attachment: action.payload.attachment }
      }
    })
  },
})

export const ChatsWrittenActions = slice.actions

export default slice.reducer
